import React from 'react'
import { useNavigate } from 'react-router-dom'
import newLogo from '../vendors/images/newLogo.png'

export default function Header() {
const nav = useNavigate()
	function logout(e){
		e.preventDefault()
		localStorage.clear()
		nav('/')
	}

  return (
    <div>
        <div class="header">
		<div class="header-left">
			<div class="menu-icon dw dw-menu"></div>
			<div class="search-toggle-icon dw dw-search2" data-toggle="header_search"><i class="fa fa-search" aria-hidden="true"></i>
			</div>
			<div class="header-search">
			</div>
		</div>
		<div class="header-right">
			
			<div class="user-info-dropdown">
				<div class="dropdown">
					<a class="dropdown-toggle" href="#" role="button" data-toggle="dropdown">
						<span class="user-icon">
							<img src="vendors/images/photo1.jpg" width='500px' height='100px' alt=""/>
						</span>
						<span class="user-name">Firekeey Admin</span>
					</a>
					<div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
						<a class="dropdown-item" href="register"><i class="fa fa-user" aria-hidden="true"></i>Employee Register</a>
						{/* <a class="dropdown-item" href="profile.html"><i class="fa fa-cog" aria-hidden="true"></i>Setting</a> */}
						{/* <a class="dropdown-item" href="faq.html"><i class="fa fa-question" aria-hidden="true"></i>Help</a> */}
						<button class="dropdown-item" onClick={(e)=>logout(e)} ><i class="fa fa-sign-out" aria-hidden="true"></i>Log Out</button>
					</div>
				</div>
			</div>
			
		</div>
	</div>

	<div class="right-sidebar">
		<div class="sidebar-title">
			<h3 class="weight-600 font-16 text-blue">
				Layout Settings
				<span class="btn-block font-weight-400 font-12">User Interface Settings</span>
			</h3>
			<div class="close-sidebar" data-toggle="right-sidebar-close">
				<i class="fa fa-times" aria-hidden="true"></i>
			</div>
		</div>
		<div class="right-sidebar-body customscroll">
			<div class="right-sidebar-body-content">
				<h4 class="weight-600 font-18 pb-10">Header Background</h4>
				<div class="sidebar-btn-group pb-30 mb-10">
					<a href="javascript:void(0);" class="btn btn-outline-primary header-white active">White</a>
					<a href="javascript:void(0);" class="btn btn-outline-primary header-dark">Dark</a>
				</div>

				<h4 class="weight-600 font-18 pb-10">Sidebar Background</h4>
				<div class="sidebar-btn-group pb-30 mb-10">
					<a href="javascript:void(0);" class="btn btn-outline-primary sidebar-light ">White</a>
					<a href="javascript:void(0);" class="btn btn-outline-primary sidebar-dark active">Dark</a>
				</div>

				

				<div class="reset-options pt-30 text-center">
					<button class="btn btn-danger" id="reset-settings">Reset Settings</button>
				</div>
			</div>
		</div>
	</div>

	<div class="left-side-bar">
		<div class="brand-logo">
			<a href="home">
				<img src={newLogo} alt="" class="dark-logo"/>
				<img src={newLogo} alt="" class="light-logo" className='p-5'/>
			</a>
			<div class="close-sidebar" data-toggle="left-sidebar-close">
				<i class="ion-close-round"></i>
			</div>
		</div>
		<div class="menu-block customscroll">
			<div class="sidebar-menu">
				<ul id="accordion-menu">
					
					<li>
						<a href="home" class="dropdown-toggle no-arrow">
							<span><i class="fa fa-home" aria-hidden="true"></i></span><span class="mtext">Home</span>
						</a>
					</li>
					<li>
						<a href="categoriesList" class="dropdown-toggle no-arrow">
							<span><i class="fa fa-list-alt" aria-hidden="true"></i>
							</span><span class="mtext">Categories</span>
						</a>
					</li>
					<li>
						<a href="subscription" class="dropdown-toggle no-arrow">
							<span><i class="fa fa-table" aria-hidden="true"></i>
							</span><span class="mtext">Subscription</span>
						</a>
					</li>
					<li>
					    <a href='viewFAQ' class="dropdown-toggle no-arrow">
							<span ><i class="fa fa-file" aria-hidden="true"></i>
							</span><span class="mtext">FAQ</span>
						</a>
					</li>
					<li>
					{localStorage.getItem('role')=='employee'?
						<a href='viewEmployeeProfile' class="dropdown-toggle no-arrow">
							<span><i class="fa fa-table" aria-hidden="true"></i>
							</span><span class="mtext">Profile</span>
						</a>
						:
						<a href="employeesList" class="dropdown-toggle no-arrow">
							<span><i class="fa fa-table" aria-hidden="true"></i>
							</span><span class="mtext">Sub-Admin List</span>
						</a>
					}
					</li>
					<li>
					
						<a href='viewProductList' class="dropdown-toggle no-arrow">
							<span><i class="fa fa-table" aria-hidden="true"></i>
							</span><span class="mtext">Product list</span>
						</a>
					
					</li>
					<li>
					
						<a href='viewUserList' class="dropdown-toggle no-arrow">
							<span><i class="fa fa-table" aria-hidden="true"></i>
							</span><span class="mtext">User list</span>
						</a>
					
					</li>
					<li>
					
						<a href='getAllPaymentDetails' class="dropdown-toggle no-arrow">
							<span><i class="fa fa-table" aria-hidden="true"></i>
							</span><span class="mtext">Payment Details</span>
						</a>
					
					</li>
					{/* <li>
					
						<a href='allSubscribeDetails' class="dropdown-toggle no-arrow">
							<span><i class="fa fa-table" aria-hidden="true"></i>
							</span><span class="mtext">Subscribe Details</span>
						</a>
					
					</li> */}
			
			
					{/* <li class="dropdown">
						<a href="javascript:;" class="dropdown-toggle">
							<span ><i class="fa fa-file" aria-hidden="true"></i>
							</span><span class="mtext">Additional Pages</span>
						</a>
						<ul class="submenu">
							<li><a href="login.html">Login</a></li>
							<li><a href="forgot-password.html">Forgot Password</a></li>
							<li><a href="reset-password.html">Reset Password</a></li>
						</ul>
					</li>
					<li>
						<a href="400.html" class="dropdown-toggle no-arrow">
							<span><i class="fa fa-exclamation-circle" aria-hidden="true"></i>
							</span><span class="mtext">400</span>
						</a>
					</li>
					

					<li class="dropdown">
						<a href="javascript:;" class="dropdown-toggle">
							<span><i class="fa fa-file-text" aria-hidden="true"></i>
							</span><span class="mtext">Extra Pages</span>
						</a>
						<ul class="submenu">
							<li><a href="blog.html">Blog</a></li>
							<li><a href="blog-detail.html">Blog Detail</a></li>
							<li><a href="product.html">Product</a></li>
							<li><a href="product-detail.html">Product Detail</a></li>
							<li><a href="profile.html">Profile</a></li>
						</ul>
					</li>
					<li>
						<a href="chat.html" class="dropdown-toggle no-arrow">
							<span>
								<i class="fa fa-comments-o" aria-hidden="true"></i>
							</span><span class="mtext">Chat</span>
						</a>
					</li>
					<li>
						<a href="invoice.html" class="dropdown-toggle no-arrow">
							<span><i class="fa fa-files-o" aria-hidden="true"></i>
							</span><span class="mtext">Invoice</span>
						</a>
					</li>
					<li>
						<div class="dropdown-divider"></div>
					</li>
					 */}
				</ul>
			</div>
		</div>
	</div>
    </div>
  )
}
