import React,{useState} from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import LoadingPage from '../COMPONENTS/loadingPage'

export default function Register() {
	// <LoadingPage/>
const [userName, setuserName] = useState('')
const [email, setemail] = useState('')
const [contact, setcontact] = useState('')
const [password, setpassword] = useState('')
const createAccountData = {userName,email,contact,password}
const token = localStorage.getItem('token')
const nav = useNavigate()

function navToEmployeeList(){
	nav('/employeesList')
}

function CreateAccount(e){
    e.preventDefault()
    fetch('https://node.firehrm.com/FK/admin/add-subAdmin',{
        method:'POST',
        headers:{
            'Authorization':token ,
            'Content-Type': 'application/json'
          },
        body: JSON.stringify(createAccountData)
        })
        .then((response)=> response.json())
        
        .then((data) => {
            if (data.message=="add employee successfully"){
				navToEmployeeList()
			}
            console.log('Response:', data);
        })	
        .catch((error) => {
        console.log('Error:', error);
        });
    }

  return (
    <div>
        <div className="login-header box-shadow">
		<div className="container-fluid d-flex justify-content-between align-items-center">
			<div className="brand-logo">
				<a href="login.html">
					<img src="vendors/images/logo.png" alt=""/>
				</a>
			</div>
			<div className="login-menu">
				<ul>
					{/* <li><a href="login.html">Login</a></li> */}
				</ul>
			</div>
		</div>
	</div>
	<div className="register-page-wrap d-flex align-items-center flex-wrap justify-content-center">
		<div className="container">
			<div className="row align-items-center">
				<div className="col-md-6 col-lg-7">
					<img src="vendors/images/register-page-img.png" alt=""/>
				</div>
				<div className="col-md-6 col-lg-5">
					<div className="register-box bg-white box-shadow border-radius-10">
						<div className="wizard-content">
                        <div className="login-box bg-white box-shadow border-radius-10">
						<div className="login-title">
							<h2 className="text-center text-primary">Create Employee Profile</h2>
						</div>
                        <form>
							<div className="select-role">
							</div>
							<div className="input-group custom">
								<input onChange={(e)=>setuserName(e.target.value)}  type="text" className="form-control form-control-lg" placeholder="Username"/>
								<div className="input-group-append custom">
									<span className="input-group-text">

										</span>
								</div>
							</div>
                            <div className="input-group custom">
								<input onChange={(e)=>setemail(e.target.value)} type="email" className="form-control form-control-lg" placeholder="Email"/>
								<div className="input-group-append custom">
									<span className="input-group-text">

										</span>
								</div>
							</div>
                            <div className="input-group custom">
								<input onChange={(e)=>setcontact(e.target.value)} type="tel" className="form-control form-control-lg" placeholder="Contact Number"/>
								<div className="input-group-append custom">
									<span className="input-group-text">

										</span>
								</div>
							</div>
							<div className="input-group custom">
								<input onChange={(e)=>setpassword(e.target.value)} type="password" className="form-control form-control-lg" placeholder="**********"/>
								<div className="input-group-append custom">
									<span className="input-group-text"></span>
								</div>
							</div>
							
							<div className="row">
								<div className="col-sm-12">
									<div className="input-group mb-0">
										<button onClick={(e)=>(CreateAccount(e))} className="btn btn-outline-primary btn-lg btn-block" >Create Account</button>
									</div>
								</div>
							</div>
						</form>
                        </div>
						</div>
					</div>
				</div> 
			</div>
		</div>
	</div>
	{/* <!-- success Popup html Start --> */}
	<button type="button" id="success-modal-btn" hidden data-toggle="modal" data-target="#success-modal" data-backdrop="static">Launch modal</button>
	<div className="modal fade" id="success-modal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
		<div className="modal-dialog modal-dialog-centered max-width-400" role="document">
			<div className="modal-content">
				<div className="modal-body text-center font-18">
					<h3 className="mb-20">Form Submitted!</h3>
					<div className="mb-30 text-center"><img src="vendors/images/success.png"/></div>
					Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
				</div>
				<div className="modal-footer justify-content-center">
					<a href="login.html" className="btn btn-primary">Done</a>
				</div>
			</div>
		</div>
	</div>
	{/* <!-- success Popup html End --> */}
    <Helmet>
	{/* <!-- js --> */}
    
	<script type="media_type" src="vendors/scripts/core.js"></script>
	<script type="media_type" src="vendors/scripts/script.min.js"></script>
	<script type="media_type" src="vendors/scripts/process.js"></script>
	<script type="media_type" src="vendors/scripts/layout-settings.js"></script>
	<script type="application/javascript" src="src/plugins/jquery-steps/jquery.steps.js"></script>
	<script type="application/javascript" src="vendors/scripts/step-settings.js"></script>
    </Helmet>
    </div>
  )
}
