import React,{useState,useEffect} from 'react'
import axios from 'axios'
import Header from '../COMPONENTS/header'

export default function ViewProductList() {
    const token = localStorage.getItem('token')
	const [ProductData, setProductData] = useState('');
    async function getProductData() {
        await axios({
          'method': 'GET',
          'url': `https://node.firehrm.com/FK/owner/getAll-productList`,
          'headers': {
              'Authorization':token,
            'Content-Type': 'application/json',
          },
        }).then((data) => {
          console.log(data)
        setProductData(data.data.data)
    
        }).catch((error)=>{
            console.log(error);
        })
      };

      useEffect(()=>{getProductData()},[])

  return (
	<div>
	<Header/>
	<div class="main-container">
		<div class="pd-ltr-20">
    <div class="card-box mb-30">
				{ProductData?<h2 class="h4 pd-20">All Product Data</h2>:''}
				<table class="data-table table nowrap">
					<thead>
						{ProductData?
                        <tr>
							<th class="table-plus datatable-nosort">Image</th>
							<th>Title</th>
                            <th>Ad Type</th>
							<th>Category</th>
							<th>Sub-Category</th>
							<th>Price</th>
							<th>City</th>
                            <th>Subscription Plan</th>
							{/* <th class="datatable-nosort">Action</th> */}
						</tr>
                        :""} 
					</thead>
					<tbody>
						{ProductData && ProductData?.map((x=>
                        <>
                        <tr>
							<td class="table-plus">
								<img src={x?.productDetails?.productImage?.[0]} width="70" height="70" alt=""/>
							</td>
							<td>
								<h5 class="font-16">{x?.productDetails?.productTitle}</h5>
								
							</td>
							<td>{x?.productDetails?.adType}</td>
							<td>{x?.productDetails?.productCategory}</td>
							<td>{x?.productDetails?.productSubCategory}</td>
							<td>{x?.productDetails?.productPrice}</td>
                            <td>{x?.productDetails?.productLocation}</td>
                            <td>{x?.subscriptionPlanName}</td>
							{/* <td>
								<div class="dropdown">
									<a class="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle" href="#" role="button" data-toggle="dropdown">
										<i class="dw dw-more"></i>
									</a>
									<div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
										<a class="dropdown-item" href="#"><i class="dw dw-eye"></i> View</a>
										<a class="dropdown-item" href="#"><i class="dw dw-edit2"></i> Edit</a>
										<a class="dropdown-item" href="#"><i class="dw dw-delete-3"></i> Delete</a>
									</div>
								</div>
							</td> */}
						</tr>
                        </>
                        ))}
                    </tbody>
                    
                </table>
            </div>
		</div>
		</div>
		</div>
  )
}
