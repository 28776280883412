import React,{useState} from 'react'
import {Helmet} from "react-helmet";
import { useNavigate } from 'react-router-dom';
import LoadingPage from '../COMPONENTS/loadingPage'
import newLogo from '../vendors/images/newLogo.png'

export default function Login() {
	// <LoadingPage/>
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const AdminLoginData = {email,password}
	const nav = useNavigate()
    
	function navToHome(){
		nav('/home')
	}
    function AdminLogin(e){
		e.preventDefault()
		fetch('https://node.firehrm.com/FK/admin/login',{
			method:'POST',
			headers:{
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			  },
			body: JSON.stringify(AdminLoginData)
			})
			.then((response)=> response.json())
			
			.then((data) => {
			
				console.log('Response:', data);
				localStorage.setItem('token',data.token)
				localStorage.setItem('role',data.data.role)
				localStorage.setItem('id',data.data._id)
				if (data.message == "Login Successfully"){
					navToHome()
				}
			})	
			.catch((error) => {
			console.log('Error:', error);
			});
		}

		
  return (
    <div>
        <div className="login-header box-shadow">
		<div className="container-fluid d-flex justify-content-between align-items-center">
			<div className="brand-logo">
				<a href="">
					<img src={newLogo} alt="" className='p-5'/>
				</a>
			</div>
			<div className="login-menu">
				<ul>
					{/* <li><a href="register">Employee Register</a></li> */}
				</ul>
			</div>
		</div>
	</div>
	<div className="login-wrap d-flex align-items-center flex-wrap justify-content-center">
		<div className="container">
			<div className="row align-items-center">
				<div className="col-md-6 col-lg-7">
					<img src="vendors/images/login-page-img.png" alt=""/>
				</div>
				<div className="col-md-6 col-lg-5">
					<div className="login-box bg-white box-shadow border-radius-10">
						<div className="login-title">
							<h2 className="text-center text-primary">Login To FireKeey</h2>
						</div>
						<form>
							<div className="select-role">
							</div>
							<div className="input-group custom">
								<input onChange={(e)=>setEmail(e.target.value)} type="text" className="form-control form-control-lg" placeholder="Username"/>
								<div className="input-group-append custom">
									<span className="input-group-text"><i className="fa fa-user" aria-hidden="true"></i>

										</span>
								</div>
							</div>
							<div className="input-group custom">
								<input onChange={(e)=>setPassword(e.target.value)} type="password" className="form-control form-control-lg" placeholder="**********"/>
								<div className="input-group-append custom">
									<span className="input-group-text"><i className="fa fa-lock" aria-hidden="true"></i></span>
								</div>
							</div>
							<div className="row pb-30">
								<div className="col-6">
									{/* <div className="custom-control custom-checkbox">
										<input type="checkbox" className="custom-control-input" id="customCheck1"/>
										<label className="custom-control-label" for="customCheck1">Remember</label>
									</div> */}
								</div>
								<div className="col-6">
									{/* <div className="forgot-password"><a href="forgot-password.html">Forgot Password</a></div> */}
								</div>
							</div>
							<div className="row">
								<div className="col-sm-12">
									<div className="input-group mb-0">
										
										<button onClick={(e)=>AdminLogin(e)} className="btn btn-primary btn-lg btn-block" >Sign In</button>
									</div>
									{/* <div className="font-16 weight-600 pt-10 pb-10 text-center" data-color="#707373">OR</div>
									<div className="input-group mb-0">
										<a className="btn btn-outline-primary btn-lg btn-block" href="register.html">Register To Create Account</a>
									</div> */}
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
	{/* <!-- js --> */}
    <Helmet>
	<script type="media_type" src="vendors/scripts/core.js"></script>
	<script type="media_type" src="vendors/scripts/script.min.js"></script>
	<script type="media_type" src="vendors/scripts/process.js"></script>
	<script type="media_type" src="vendors/scripts/layout-settings.js"></script>
    </Helmet>
    </div>
  )
}
