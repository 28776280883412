import React,{useState,useEffect} from 'react'
import axios from 'axios';
import Header from '../COMPONENTS/header';

export default function ViewUserList() {
    const token = localStorage.getItem('token')
    const [UserData, setUserData] = useState('');

    async function getUserData() {
		await axios({
		  'method': 'GET',
		  'url': `https://node.firehrm.com/FK/user/getAll`,
		  'headers': {
			  'Authorization':token,
			'Content-Type': 'application/json',
		  },
		}).then((data) => {
		  console.log(data)
		setUserData(data.data.data)

		}).catch((error)=>{
			console.log(error);
		})
	  };

      useEffect(()=>{getUserData()},[])

  return (
    <div>
    <Header/>
    <div class="main-container">
		<div class="pd-ltr-20">
        <div class="card-box mb-30">
				{UserData?<h2 class="h4 pd-20">Registered User Data</h2>:''}
				<table class="data-table table nowrap">
					<thead>
						{UserData?
                        <tr>
							<th class="table-plus datatable-nosort">Image</th>
							<th>Name</th>
							<th>Account</th>
							<th>Ph.No</th>
							<th>Email</th>
							<th>City</th>
							{/* <th class="datatable-nosort">Action</th> */}
						</tr>
                        :""} 
					</thead>
					<tbody>
						{UserData && UserData?.map((x=>
                        <tr>
							<td class="table-plus">
								<img src={x?.profileImage} width="70" height="70" alt=""/>
							</td>
							<td>
								<h5 class="font-16">{x?.userName}</h5>
								
							</td>
							<td>{x?.accountType}</td>
							<td>{x?.phoneNumber}</td>
							<td>{x?.email}</td>
							<td>{x?.city}</td>
							{/* <td>
								<div class="dropdown">
									<a class="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle" href="#" role="button" data-toggle="dropdown">
										<i class="dw dw-more"></i>
									</a>
									<div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
										<a class="dropdown-item" href="#"><i class="dw dw-eye"></i> View</a>
										<a class="dropdown-item" href="#"><i class="dw dw-edit2"></i> Edit</a>
										<a class="dropdown-item" href="#"><i class="dw dw-delete-3"></i> Delete</a>
									</div>
								</div>
							</td> */}
						</tr>
                        ))}

					</tbody>
                    
				</table>
			</div>
        </div>
    </div>

    </div>
  )
}
