import React,{useState,useEffect} from 'react'
import { Helmet } from 'react-helmet'
import Header from '../COMPONENTS/header'
import LoadingPage from '../COMPONENTS/loadingPage'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'

function CategoryView() {
    const nav = useNavigate()
    const state = useLocation()
	console.log(state.state)
	const categoryName = state.state.categoryName
	console.log(categoryName);
	const id = state.state._id
	const [subcategorycount, setsubcategorycount] = useState([]);
	console.log(subcategorycount);
    const token = localStorage.getItem('token')
const [categoriesData,setCategoriesData] = useState('')
	async function getCategories() {
		await axios({
		  'method': 'GET',
		  'url': `https://node.firehrm.com/FK/admin/getById-category/`+id,
		  'headers': {
			//   'Authorization':token,
			'Content-Type': 'application/json',
		  },
		}).then((data) => {
		  console.log(data)
		setCategoriesData(data.data.data)
		}).catch((error)=>{
			console.log(error);
		})
	  };

	  useEffect(()=>{getCategories()},[])

	  async function getSubCategoryCount() {
		await axios({
		  'method': 'GET',
		  'url': 'https://node.firehrm.com/FK/owner/subCategory-count/'+categoryName,
		  'headers': {
			//   'Authorization':token,
			'Content-Type': 'application/json',
		  },
		}).then((data) => {
		  console.log(data.data.arr)
		 setsubcategorycount(data.data.arr)
		}).catch((error)=>{
			console.log(error);
		})
	  };

	  useEffect(()=>{getSubCategoryCount()},[])

	  function editCategory(e,x){
		nav('/editCategory',{state:x})
	}

	function deleteCategory(e){
		e.preventDefault()
		axios({
			'method': 'delete',
			'url': 'https://node.firehrm.com/FK/admin/delete-category/'+id,
			'headers': {
			    'Authorization':token,
			  'Content-Type': 'application/json',
			},
		  }).then((data) => {
			console.log(data)
			window.location.reload(false)
		  }).catch((error)=>{
			  console.log(error);
		  })
		};

	
		const datas = [id,categoriesData]
		console.log(categoriesData)
    return (
        <div>
           {/* <LoadingPage/> */}
           <Header/>
   
    <div class="main-container">
		<div class="pd-ltr-20 xs-pd-20-10">
			<div class="min-height-200px">
				<div class="page-header">
					<div class="row">
						<div class="col-md-6 col-sm-12">
							<div class="title">
								<h4>Category</h4>
							</div>
							<nav aria-label="breadcrumb" role="navigation">
								<ol class="breadcrumb">
									<li class="breadcrumb-item"><a href="categoriesList">Categories List</a></li>
									<li class="breadcrumb-item active" aria-current="page">Category</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
				{/* <!-- Simple Datatable start --> */}
				<div class="card-box mb-30">
					<div class="pd-20">
						<img src={categoriesData.categoryImage} className='ml-5' alt="category Image" />
						<h4 class="text-blue h4 ml-5 mt-5">{categoriesData.categoryName}</h4>
						<p class="mb-0 ml-5">List of all sub categories in the {categoriesData.categoryName} </p>
					</div>
					<div class="pb-20">
						<table class="data-table table stripe hover nowrap">
							<thead>
								<tr>
									{/* <th class="table-plus datatable-nosort">Sub Categories</th>
									
									<th>Total Products</th> */}
									
									<th class="datatable-nosort" ><button onClick={(e)=>editCategory(e,datas)} className="btn btn-warning btn-lg" >Edit</button></th>

									<th class="datatable-nosort"><button onClick={(e)=>deleteCategory(e)} className="btn btn-danger btn-lg" >Delete</button></th>
								</tr>
							</thead>
							<tbody>
							
                          
						    
						   <>
						  {/* {subcategorycount.map((x=> */}
								<tr>
								<th class="table-plus datatable-nosort">Sub Categories</th> {categoriesData.subCategoryName && categoriesData.subCategoryName.map((singleSubCategory)=>
									<td class="table-plus">{singleSubCategory}</td>
									)}
								</tr>
								<tr><th>Total Products</th>
									{subcategorycount.map((x=>
									<td>{x}</td>
									))} 
								</tr>
								 
								
								</>
								 
							
							</tbody>
						</table>
					</div>
				</div>

           <Helmet>
                {/* <!-- js --> */}
	<script src="vendors/scripts/core.js"></script>
	<script src="vendors/scripts/script.min.js"></script>
	<script src="vendors/scripts/process.js"></script>
	<script src="vendors/scripts/layout-settings.js"></script>
	<script src="src/plugins/datatables/js/jquery.dataTables.min.js"></script>
	<script src="src/plugins/datatables/js/dataTables.bootstrap4.min.js"></script>
	<script src="src/plugins/datatables/js/dataTables.responsive.min.js"></script>
	<script src="src/plugins/datatables/js/responsive.bootstrap4.min.js"></script>
	{/* <!-- buttons for Export datatable --> */}
	<script src="src/plugins/datatables/js/dataTables.buttons.min.js"></script>
	<script src="src/plugins/datatables/js/buttons.bootstrap4.min.js"></script>
	<script src="src/plugins/datatables/js/buttons.print.min.js"></script>
	<script src="src/plugins/datatables/js/buttons.html5.min.js"></script>
	<script src="src/plugins/datatables/js/buttons.flash.min.js"></script>
	<script src="src/plugins/datatables/js/pdfmake.min.js"></script>
	<script src="src/plugins/datatables/js/vfs_fonts.js"></script>
	{/* <!-- Datatable Setting js --> */}
	<script src="vendors/scripts/datatable-setting.js"></script>
           </Helmet>
           </div>
           </div>
        
        </div>
       </div> 
    )
}

export default CategoryView
