import React,{useState,useEffect} from 'react'
import { Helmet } from 'react-helmet'
import Header from '../COMPONENTS/header'
import LoadingPage from '../COMPONENTS/loadingPage'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

export default function ViewFAQ() {
  const nav = useNavigate()
const token = localStorage.getItem('token')
const [FAQ, setFAQ] = useState('');
  async function getFAQ() {
    await axios({
      'method': 'GET',
      'url': `https://node.firehrm.com/FK/admin/getAll`,
      'headers': {
        // 'Authorization':token,
      'Content-Type': 'application/json',
      },
    }).then((data) => {
      console.log(data)
      setFAQ(data.data.data)
    }).catch((error)=>{
      console.log(error);
    })
    };
  
    useEffect(()=>{getFAQ()},[])
  
    function deleteFAQ(e,id){
      e.preventDefault()
      axios({
        'method': 'delete',
        'url': 'https://node.firehrm.com/FK/admin/remove-FAQ/'+id,
        'headers': {
          'Authorization':token,
          'Content-Type': 'application/json',
        },
        }).then((data) => {
        console.log(data)
        window.location.reload(false)
        }).catch((error)=>{
          console.log(error);
        })
      };
 
      function navToUpdateFAQ(e,x){
        nav('/updateFAQ',{state:x})
      }
      
  return (
    <div>
       <div>
           {/* <LoadingPage/> */}
           <Header/>

    <div class="main-container">
		<div class="pd-ltr-20 xs-pd-20-10">
			<div class="min-height-200px">
				<div class="page-header">
					<div class="row">
						<div class="col-md-6 col-sm-12">
							<div class="title">
								<h4>FAQ</h4>
							</div>
							<nav aria-label="breadcrumb" role="navigation">
								<ol class="breadcrumb">
									<li class="breadcrumb-item"><a href="home">Home</a></li>
									<li class="breadcrumb-item active" aria-current="page">FAQ List</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
				{/* <!-- Simple Datatable start --> */}
				<div class="card-box mb-30">
					<div class="pd-20">
						<h4 class="text-blue h4">FAQ's Q & A </h4>
						<p class="mb-0">List of FAQ</p>
					</div>
					<div class="pb-20">
						<table class="data-table table stripe hover nowrap">
							<thead>
								<tr>
									<th class="table-plus datatable-nosort">Question</th>
									
									<th class="table-plus datatable-nosort">Answer</th>
	
									<th class="datatable-nosort"><a href='addFAQ' className="btn btn-success btn-lg" >Add New</a></th>
								</tr>
							</thead>
							<tbody>
								{FAQ && FAQ.map((QA)=>
								
								<tr>
									 <td class="table-plus">{QA.question}</td>
									
									<td>{QA.answer}</td>
                                                        
                                    <td>
                                        <button 
                                        onClick={(e)=>navToUpdateFAQ(e,QA)} 
                                        className="btn btn-info btn-lg">Edit</button>
                                    </td>

                                    <td>
                                         <button 
                                        onClick={(e)=>deleteFAQ(e,QA._id)} 
                                        className="btn btn-danger btn-lg">Delete</button>
                                    </td>
                                                    </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

           <Helmet>
                {/* <!-- js --> */}
	<script src="vendors/scripts/core.js"></script>
	<script src="vendors/scripts/script.min.js"></script>
	<script src="vendors/scripts/process.js"></script>
	<script src="vendors/scripts/layout-settings.js"></script>
	<script src="src/plugins/datatables/js/jquery.dataTables.min.js"></script>
	<script src="src/plugins/datatables/js/dataTables.bootstrap4.min.js"></script>
	<script src="src/plugins/datatables/js/dataTables.responsive.min.js"></script>
	<script src="src/plugins/datatables/js/responsive.bootstrap4.min.js"></script>
	{/* <!-- buttons for Export datatable --> */}
	<script src="src/plugins/datatables/js/dataTables.buttons.min.js"></script>
	<script src="src/plugins/datatables/js/buttons.bootstrap4.min.js"></script>
	<script src="src/plugins/datatables/js/buttons.print.min.js"></script>
	<script src="src/plugins/datatables/js/buttons.html5.min.js"></script>
	<script src="src/plugins/datatables/js/buttons.flash.min.js"></script>
	<script src="src/plugins/datatables/js/pdfmake.min.js"></script>
	<script src="src/plugins/datatables/js/vfs_fonts.js"></script>
	{/* <!-- Datatable Setting js --> */}
	<script src="vendors/scripts/datatable-setting.js"></script>
           </Helmet>
           </div>
           </div>
        
        </div> 
       </div> 
    </div>
  )
}
