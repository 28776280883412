import React,{useState,useEffect} from 'react'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

export default function HomeTables() {
    const token = localStorage.getItem('token')
    const [UserCount,setUserCount] = useState('')
    const [ProductCount, setProductCount] = useState('')
    const [UserData, setUserData] = useState('');
    const [ProductData, setProductData] = useState('')
	const [PremiumCount, setPremiumCount] = useState('');
    const [StandardCount, setStandardCount] = useState('');
    const nav = useNavigate()
	
	function getProductData(e){
		
		nav('viewProductList')
	}

	async function getUserCount() {
		await axios({
		  'method': 'GET',
		  'url': `https://node.firehrm.com/FK/user/getAll`,
		  'headers': {
			  'Authorization':token,
			'Content-Type': 'application/json',
		  },
		}).then((data) => {
		  console.log(data)
		setUserCount(data.data.data)

		}).catch((error)=>{
			console.log(error);
		})
	  };

	  useEffect(()=>{getUserCount()},[])

	  async function getPremiumCount() {
		await axios({
		  'method': 'GET',
		  'url': `https://node.firehrm.com/FK/owner/premiumPlan-count`,
		  'headers': {
			//   'Authorization':token,
			'Content-Type': 'application/json',
		  },
		}).then((data) => {
		  console.log(data)
		 setPremiumCount(data.data.count)

		}).catch((error)=>{
			console.log(error);
		})
	  };

	  useEffect(()=>{getPremiumCount()},[])

	  async function getStandardCount() {
		await axios({
		  'method': 'GET',
		  'url': `https://node.firehrm.com/FK/owner/standardPlan-count`,
		  'headers': {
			//   'Authorization':token,
			'Content-Type': 'application/json',
		  },
		}).then((data) => {
		  console.log(data)
		 setStandardCount(data.data.count)

		}).catch((error)=>{
			console.log(error);
		})
	  };

	  useEffect(()=>{getStandardCount()},[])


      async function getUserData() {
		await axios({
		  'method': 'GET',
		  'url': `https://node.firehrm.com/FK/user/getAll`,
		  'headers': {
			  'Authorization':token,
			'Content-Type': 'application/json',
		  },
		}).then((data) => {
		  console.log(data)
		setUserData(data.data.data)

		}).catch((error)=>{
			console.log(error);
		})
	  };

	  useEffect(()=>{getUserData()},[])

async function getProductCount() {
    await axios({
      'method': 'GET',
      'url': `https://node.firehrm.com/FK/owner/getAll-productList`,
      'headers': {
          'Authorization':token,
        'Content-Type': 'application/json',
      },
    }).then((data) => {
      console.log(data)
    setProductCount(data.data.data)

    }).catch((error)=>{
        console.log(error);
    })
  };

  useEffect(()=>{getProductCount()},[])

  async function getProductData() {
    await axios({
      'method': 'GET',
      'url': `https://node.firehrm.com/FK/owner/getAll-productList`,
      'headers': {
          'Authorization':token,
        'Content-Type': 'application/json',
      },
    }).then((data) => {
      console.log(data)
    setProductData(data.data.data)

    }).catch((error)=>{
        console.log(error);
    })
  };
  useEffect(()=>{getProductData()},[])


  return (
    <div class="main-container">
		<div class="pd-ltr-20">
			<div class="card-box pd-20 height-100-p mb-30">
				<div class="row align-items-center">
					<div class="col-md-4">
						<img src="vendors/images/banner-img.png" alt=""/>
					</div>
					<div class="col-md-8">
						<h4 class="font-20 weight-500 mb-10 text-capitalize">
							Welcome back <div class="weight-600 font-30 text-blue">Firekeey Admin!</div>
						</h4>
						<p class="font-18 max-width-600">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Unde hic non repellendus debitis iure, doloremque assumenda. Autem modi, corrupti, nobis ea iure fugiat, veniam non quaerat mollitia animi error corporis.</p>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-xl-3 mb-30">
					<button 
                    onClick={(e)=>getProductData(e)} 
                    class="card-box height-100-p widget-style1">
						<div class="d-flex flex-wrap align-items-center">
							<div class="progress-data">
								<div id="chart"></div>
							</div>
							<div class="widget-data">
								<div class="h4 mb-0">{ProductCount?.length}</div>
								<div class="weight-600 font-14">Products</div>
							</div>
						</div>
					</button>
				</div>
				<div class="col-xl-3 mb-30">
					<button onClick={(e)=>getUserData(e)} class="card-box height-100-p widget-style1">
						<div class="d-flex flex-wrap align-items-center">
							<div class="progress-data">
								<div id="chart2"></div>
							</div>
							<div class="widget-data">
								<div class="h4 mb-0">{UserCount.length}</div>
								<div class="weight-600 font-14">Registered Users</div>
							</div>
						</div>
					</button>
				</div>
				<div class="col-xl-3 mb-30">
					<button onClick={(e)=>getProductData(e)} class="card-box height-100-p widget-style1">
						<div class="d-flex flex-wrap align-items-center">
							<div class="progress-data">
								<div id="chart3"></div>
							</div>
							<div class="widget-data">
								<div class="h4 mb-0">{PremiumCount}</div>
								<div class="weight-600 font-14">Premium Products</div>
							</div>
						</div>
					</button>
				</div>
				<div class="col-xl-3 mb-30">
					<button onClick={(e)=>getProductData(e)} class="card-box height-100-p widget-style1">
						<div class="d-flex flex-wrap align-items-center">
							<div class="progress-data">
								<div id="chart4"></div>
							</div>
							<div class="widget-data">
								<div class="h4 mb-0">{StandardCount}</div>
								<div class="weight-600 font-14">Standard Products</div>
							</div>
						</div>
					</button>
				</div>
			</div>
			<div class="row">
				<div class="col-xl-8 mb-30">
					<div class="card-box height-100-p pd-20">
						<h2 class="h4 mb-20">Activity</h2>
						<div id="chart5"></div>
					</div>
				</div>
				<div class="col-xl-4 mb-30">
					<div class="card-box height-100-p pd-20">
						<h2 class="h4 mb-20">Lead Target</h2>
						<div id="chart6"></div>
					</div>
				</div>
			</div>
            
			<div class="card-box mb-30">
				{UserData?<h2 class="h4 pd-20">Registered User Data</h2>:''}
				<table class="data-table table nowrap">
					<thead>
						{UserData?
                        <tr>
							<th class="table-plus datatable-nosort">Image</th>
							<th>Name</th>
							<th>Account</th>
							<th>Ph.No</th>
							<th>Email</th>
							<th>City</th>
							{/* <th class="datatable-nosort">Action</th> */}
						</tr>
                        :""} 
					</thead>
					<tbody>
						{UserData && UserData?.map((x=>
                        <tr>
							<td class="table-plus">
								<img src={x?.profileImage} width="70" height="70" alt=""/>
							</td>
							<td>
								<h5 class="font-16">{x?.userName}</h5>
								
							</td>
							<td>{x?.accountType}</td>
							<td>{x?.phoneNumber}</td>
							<td>{x?.email}</td>
							<td>{x?.city}</td>
							{/* <td>
								<div class="dropdown">
									<a class="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle" href="#" role="button" data-toggle="dropdown">
										<i class="dw dw-more"></i>
									</a>
									<div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
										<a class="dropdown-item" href="#"><i class="dw dw-eye"></i> View</a>
										<a class="dropdown-item" href="#"><i class="dw dw-edit2"></i> Edit</a>
										<a class="dropdown-item" href="#"><i class="dw dw-delete-3"></i> Delete</a>
									</div>
								</div>
							</td> */}
						</tr>
                        ))}

					</tbody>
                    
				</table>
			</div>
            <div class="card-box mb-30">
				{ProductData?<h2 class="h4 pd-20">All Product Data</h2>:''}
				<table class="data-table table nowrap">
					<thead>
						{ProductData?
                        <tr>
							<th class="table-plus datatable-nosort">Image</th>
							<th>Title</th>
                            <th>Ad Type</th>
							<th>Category</th>
							<th>Sub-Category</th>
							<th>Price</th>
							<th>City</th>
                            <th>Subscription Plan</th>
							{/* <th class="datatable-nosort">Action</th> */}
						</tr>
                        :""} 
					</thead>
					<tbody>
						{ProductData && ProductData?.map((x=>
                        <>
                        <tr>
							<td class="table-plus">
								<img src={x?.productDetails?.productImage?.[0]} width="70" height="70" alt=""/>
							</td>
							<td>
								<h5 class="font-16">{x?.productDetails?.productTitle}</h5>
								
							</td>
							<td>{x?.productDetails?.adType}</td>
							<td>{x?.productDetails?.productCategory}</td>
							<td>{x?.productDetails?.productSubCategory}</td>
							<td>{x?.productDetails?.productPrice}</td>
                            <td>{x?.productDetails?.productLocation}</td>
                            <td>{x?.subscriptionPlanName}</td>
							{/* <td>
								<div class="dropdown">
									<a class="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle" href="#" role="button" data-toggle="dropdown">
										<i class="dw dw-more"></i>
									</a>
									<div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
										<a class="dropdown-item" href="#"><i class="dw dw-eye"></i> View</a>
										<a class="dropdown-item" href="#"><i class="dw dw-edit2"></i> Edit</a>
										<a class="dropdown-item" href="#"><i class="dw dw-delete-3"></i> Delete</a>
									</div>
								</div>
							</td> */}
						</tr>
                        </>
                        ))}
                    </tbody>
                    
                </table>
            </div>
			
		</div>
        <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-119386393-1"></script>
        <script src="vendors/scripts/core.js"></script>
        <script src="vendors/scripts/script.min.js"></script>
        <script src="vendors/scripts/process.js"></script>
        <script src="vendors/scripts/layout-settings.js"></script>
        <script src="src/plugins/apexcharts/apexcharts.min.js"></script>
        <script src="src/plugins/datatables/js/jquery.dataTables.min.js"></script>
        <script src="src/plugins/datatables/js/dataTables.bootstrap4.min.js"></script>
        <script src="src/plugins/datatables/js/dataTables.responsive.min.js"></script>
        <script src="src/plugins/datatables/js/responsive.bootstrap4.min.js"></script>
	    <script src="vendors/scripts/dashboard.js"></script>
        </Helmet>
	</div>
  )
}
