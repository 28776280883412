import React,{useState,useEffect} from 'react'
import { Helmet } from 'react-helmet'
import Header from '../COMPONENTS/header'
import LoadingPage from '../COMPONENTS/loadingPage'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'

export default function EditCategory() {
const state = useLocation()
console.log(state);
// const id = 
const token = localStorage.getItem('token')
const [categoryImage, setProfileImage] = useState('');
const [profileImage1,setProfileImage1]=useState('');
const [one, setone] = useState(state?.state?.[1]?.subCategoryName?.[0])
const [two, settwo] = useState(state?.state?.[1]?.subCategoryName?.[1])
const [three, setthree] = useState(state?.state?.[1]?.subCategoryName?.[2])
const [four, setfour] = useState(state?.state?.[1]?.subCategoryName?.[3])
const [five, setfive] = useState(state?.state?.[1]?.subCategoryName?.[4])
const [six, setsix] = useState(state?.state?.[1]?.subCategoryName?.[5])
const [seven, setseven] = useState(state?.state?.[1]?.subCategoryName?.[6])
const [eight, seteight] = useState(state?.state?.[1]?.subCategoryName?.[7])
const [nine, setnine] = useState(state?.state?.[1]?.subCategoryName?.[8])
const [ten, setten] = useState(state?.state?.[1]?.subCategoryName?.[9])
const [categoryName, setcategoryName] = useState(state?.state?.[1]?.categoryName)
let subCategoryName = [one,two,three,four,five,six,seven,eight,nine,ten]
const nav = useNavigate()



console.log(state.state,'state')
const states= state.state[0]

// async function getCategories() {
	  
// 	await axios({
// 	  'method': 'GET',
// 	  'url': `https://node.firehrm.com/FK/admin/getById-category/`+state[0],
// 	  'headers': {
// 		//   'Authorization':token,
// 		'Content-Type': 'application/json',
// 	  },
// 	}).then((data) => {
// 	  console.log(data)
// 	setCategoriesData(data.data.data.category[0])
// 	}).catch((error)=>{
// 		console.log(error);
// 	})
//   };

//   useEffect(()=>{getCategories()},[])
console.log({categoryName,subCategoryName,categoryImage});
	function editCategory(e){
		
		e.preventDefault()
		fetch('https://node.firehrm.com/FK/admin/update-category/'+states,{
			method:'PUT',
			headers:{
				'Authorization': token,
				'Content-Type': 'application/json'
			  },
			body: JSON.stringify({categoryName,subCategoryName,categoryImage}) 
			})
			.then((response)=> response.json())
			
			.then((data) => {
			
				console.log('Response:', data);
				nav('/categoriesList')
			})	
			.catch((error) => {
			console.log('Error:', error);
			});
		
        }

		async function UploadImage(e) {
			e.preventDefault()
		  const image =new FormData()
		  image.append('image',profileImage1[0])
		  console.log(profileImage1)
		  await fetch('https://node.firehrm.com/FK/user/image',{ 
		  method: 'POST',
		  body:image
	  }).then((response)=>{
		  console.log(response)
		  return response.json()
	  }).then((resp)=>{console.log(resp);setProfileImage(resp.data.image); console.log(categoryImage);} )
	  .catch((error)=>{
		  console.log(error)
	  })
	  }
  
  return (
    <div>
           {/* <LoadingPage/> */}
           <Header/>
<div class="main-container">
<div class="pd-ltr-5 xs-pd-5-5">
	<div class="min-height-200px"></div>
	<div class="pd-20 card-box mb-30">
	<div class="clearfix">
		<div class="pull-left">
			<h4 class="text-blue h4">Edit Categories</h4>
			<p class="mb-30">Edit Categories and Sub-Categories</p>
		</div>
	</div>
	<form>
		<div class="form-group row">
			<label class="col-sm-12 col-md-2 col-form-label">Category</label>
			<div class="col-sm-12 col-md-10">
				<input placeholder={state?.state?.[1]?.categoryName} onChange={(e)=>setcategoryName(e.target.value)} class="form-control" type="text"/>
			</div>
		</div>
		<div class="form-group rowl">
		<div class="col-lg-9 " style={{marginLeft:'160px'}}>
			<div class="form-group">
				<input type="file" class="form-control" onChange={(e)=>
				setProfileImage1(e.target.files)}/>
			</div>
			<div class="col-lg-3">
			<button class="btn btn-inline btn-primary" onClick={(e)=>UploadImage(e)}>upload image</button>
		   </div>
		   
		</div>
		
		</div>
		
		{/* {SubCategoryName.map((singleSubCategory,index)=>( */}
		<div class="form-group row">
			<label class="mr-5 ml-3">Sub-Category</label>
			<div class="ml-5 mb-3">
				<input onChange={(e)=>setone(e.target.value)} placeholder={state?.state?.[1]?.subCategoryName?.[0]} class="form-control" type="text"/>
			</div>
			<label class="mr-5 ml-3">Sub-Category</label>
			<div class="ml-5 mb-3">
				<input placeholder={state?.state?.[1]?.subCategoryName?.[1]} onChange={(e)=>settwo(e.target.value)} class="form-control" type="text"/>
			</div>
			<label class="mr-5 ml-3">Sub-Category</label>
			<div class="ml-5 mb-3">
				<input placeholder={state?.state?.[1]?.subCategoryName?.[2]} onChange={(e)=>setthree(e.target.value)} class="form-control" type="text"/>
			</div>
			<label class="mr-5 ml-3">Sub-Category</label>
			<div class="ml-5 mb-3">
				<input placeholder={state?.state?.[1]?.subCategoryName?.[3]} onChange={(e)=>setfour(e.target.value)} class="form-control" type="text"/>
			</div>
			<label class="mr-5 ml-3">Sub-Category</label>
			<div class="ml-5 mb-3">
				<input placeholder={state?.state?.[1]?.subCategoryName?.[4]} onChange={(e)=>setfive(e.target.value)} class="form-control" type="text"/>
			</div>
			<label class="mr-5 ml-3">Sub-Category</label>
			<div class="ml-5 mb-3">
				<input placeholder={state?.state?.[1]?.subCategoryName?.[5]} onChange={(e)=>setsix(e.target.value)} class="form-control" type="text"/>
			</div>
			<label class="mr-5 ml-3">Sub-Category</label>
			<div class="ml-5 mb-3">
				<input placeholder={state?.state?.[1]?.subCategoryName?.[6]} onChange={(e)=>setseven(e.target.value)} class="form-control" type="text"/>
			</div>
			<label class="mr-5 ml-3">Sub-Category</label>
			<div class="ml-5 mb-3">
				<input placeholder={state?.state?.[1]?.subCategoryName?.[7]} onChange={(e)=>seteight(e.target.value)} class="form-control" type="text"/>
			</div>
			<label class="mr-5 ml-3">Sub-Category</label>
			<div class="ml-5 mb-3">
				<input placeholder={state?.state?.[1]?.subCategoryName?.[8]} onChange={(e)=>setnine(e.target.value)} class="form-control" type="text"/>
			</div>
			<label class="mr-5 ml-3">Sub-Category</label>
			<div class="ml-5 mb-3">
				<input placeholder={state?.state?.[1]?.subCategoryName?.[9]} onChange={(e)=>setten(e.target.value)} class="form-control" type="text"/>
			</div>
			
			{/* <div className="" style = {{marginLeft:'10px'}}>
				<div className="">
					<button onClick={(e)=>handleRemoveSubcategory(e,index)} className="btn btn-outline-danger" >-</button>
				</div>
			</div>
			{SubCategoryName.length -1 === index &&
			<div className="row">
			<div className="row">
			<div className="col-md-3" style={{marginLeft:'500px',marginTop:'25px'}}>
				<div className="">
					<button onClick={(e)=>handleAddSubcategory(e)} className="btn btn-outline-primary btn-lg " >+</button>
				</div>
			</div>
		</div>
		
		</div>} */}
		
		</div>
		{/* )
		)} */}
		<div className="row">
			<div className="col-sm-12">
				<div className="input-group mb-0">
					<button onClick={(e)=>editCategory(e)} className="btn btn-outline-primary btn-lg btn-block" >Edit Category</button>
				</div>
			</div>
		</div>
	</form>
</div>

    
            
           <Helmet>
           <script src="vendors/scripts/core.js"></script>
            <script src="vendors/scripts/script.min.js"></script>
            <script src="vendors/scripts/process.js"></script>
            <script src="vendors/scripts/layout-settings.js"></script>
           </Helmet>
        </div>
	</div>
	</div>
  )
}
