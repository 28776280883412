import React,{useEffect,useState} from 'react'
import Header from '../COMPONENTS/header'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import LoadingPage from '../COMPONENTS/loadingPage'

export default function ViewEmployeeProfile() {
	// <LoadingPage/>
const id =localStorage.getItem('id')
const token = localStorage.getItem('token')
const {state} = useLocation()
console.log("state",state);
const employeeId = state._id;
console.log(employeeId,'empl');

const [employeeSingleData, setemployeeSingleData] = useState('')
  async function getCall() {
		await axios({
		  'method': 'GET',
		  'url': `https://node.firehrm.com/FK/admin/getSingle-subAdmin/`+employeeId,
		  'headers': {
			'Content-Type': 'application/json',
		  },
		}).then((data) => {
		  console.log(data.data.data)
		  setemployeeSingleData(data.data.data)
		}).catch((error)=>{
			console.log(error);
		})
	  };

	  useEffect(()=>{getCall()},[])

	//   update
	const [DOB, setDOB] = useState('')
	const [city, setcity] = useState('')
	const [contact, setcontact] = useState('')
	const [country, setcountry] = useState('')
	const [email, setemail] = useState('')
	const [gender, setgender] = useState('')
	const [jobTitle, setjobTitle] = useState('')
	const [password, setpassword] = useState('')
	const [permission, setpermission] = useState('')
	const [pincode, setpincode] = useState('')
	const [profileImage, setprofileImage] = useState('')
	const [profileImage1, setprofileImage1] = useState([])
	const [State, setstate] = useState('')
	const [userName, setuserName] = useState('')
    const EmpProfileUpdateData = {DOB,city,contact,country,email,gender,jobTitle,password,permission,pincode,profileImage,State,userName}
    const nav = useNavigate()

	function navToEmpProfile(){
		nav('/home')
	}

	async function UploadImage(e) {
		e.preventDefault()
	  const image =new FormData()
	  image.append('image',profileImage1[0])
	  console.log(profileImage1)
	  await fetch('https://node.firehrm.com/FK/user/image',{ 
	  method: 'POST',
	  body:image
  }).then((response)=>{
	  console.log(response)
	  return response.json()
  }).then((resp)=>{console.log(resp);setprofileImage(resp.data.image); console.log(profileImage);} )
  .catch((error)=>{
	  console.log(error)
  })
  }

  const Permission = (event) => {
	setpermission(event.target.value);
	};

	function EmpProfileUpdate(e){
		e.preventDefault()
		fetch('https://node.firehrm.com/FK/admin/update-subAdmin/'+state._id,{
			method:'PUT',
			headers:{
				'Authorization':token,
				'Content-Type': 'application/json'
			  },
			body: JSON.stringify(EmpProfileUpdateData)
			})
			.then((response)=> response.json())
			
			.then((data) => {
			console.log('Response:', data)
			if (data.message == "upadate successfully"){
				navToEmpProfile()
			}
			
			})	
			.catch((error) => {
			console.log('Error:', error);
			});
		}
	
		// delete
		const EmpProfileDelete = (e) =>{
			e.preventDefault()
			axios({
				   'method':'delete',
				   'url':'https://node.firehrm.com/FK/admin/delete-subAdmin/'+state._id,
				   headers:{
					   'Authorization':token
				   }
			   }).then((response) => {
				   console.log(response)
				   nav('/home')
				   })
			   .catch((error)=>{
				   console.log(error)
			   })
		   }

  return (
    <div>
      <Header/>
      <div class="main-container">
		<div class="pd-ltr-20 xs-pd-20-10">
			<div class="min-height-200px">
				<div class="page-header">
					<div class="row">
						<div class="col-md-12 col-sm-12">
							<div class="title">
								<h4>Profile</h4>
							</div>
							<nav aria-label="breadcrumb" role="navigation">
								<ol class="breadcrumb">
									<li class="breadcrumb-item"><a href="index.html">Home</a></li>
									<li class="breadcrumb-item active" aria-current="page">Profile</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
				<div class="row">
        
		<div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-30">
			<div class="pd-20 card-box height-100-p">
				<div class="profile-photo">
					
					<img src={employeeSingleData.profileImage} alt="Profile Image" class="avatar-photo"/>
					<div class="modal fade" id="modal" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
						<div class="modal-dialog modal-dialog-centered" role="document">
							<div class="modal-content">
								<div class="modal-body pd-5">
									<div class="img-container">
										<img id="image" src='vendors/images/photo2.jpg' alt="Picture"/>
									</div>
								</div>
								<div class="modal-footer">
									<input type="submit" value="Update" class="btn btn-primary"/>
									<button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<h5 class="text-center h5 mb-0">{employeeSingleData.userName}</h5>
				<p class="text-center text-muted font-14">{employeeSingleData.jobTitle}</p>
				<div class="profile-info">
					<h5 class="mb-20 h5 text-blue">Contact Information</h5>
					<ul>
						<li>
							<span>Email Address:</span>
							{employeeSingleData.email}
						</li>
						<li>
							<span>Phone Number:</span>
							{employeeSingleData.contact}
						</li>
						<li>
							<span>Country:</span>
							{employeeSingleData.country}
						</li>
						<li>
							<span>Address:</span>
							{employeeSingleData.city}<br/>
							{employeeSingleData.state},{employeeSingleData.pincode}
						</li>
						<li>
							<span>Gender:</span>
							{employeeSingleData.gender}
						</li>
						<li>
							<span>Date Of Birth:</span>
							{employeeSingleData.DOB}
						</li>
						<li>
							<span>Job Title:</span>
							{employeeSingleData.jobTitle}
						</li>
						<li>
							<span>Permission:</span>
							{employeeSingleData.permission}
						</li>
					</ul>
				</div>
				<div class="profile-social">
					<h5 class="mb-20 h5 text-blue">Social Links</h5>
					<ul class="clearfix">
						<li><a href="#" class="btn" data-bgcolor="#3b5998" data-color="#ffffff"><i class="fa fa-facebook"></i></a></li>
						<li><a href="#" class="btn" data-bgcolor="#1da1f2" data-color="#ffffff"><i class="fa fa-twitter"></i></a></li>
						<li><a href="#" class="btn" data-bgcolor="#007bb5" data-color="#ffffff"><i class="fa fa-linkedin"></i></a></li>
						<li><a href="#" class="btn" data-bgcolor="#f46f30" data-color="#ffffff"><i class="fa fa-instagram"></i></a></li>
						<li><a href="#" class="btn" data-bgcolor="#c32361" data-color="#ffffff"><i class="fa fa-dribbble"></i></a></li>
						<li><a href="#" class="btn" data-bgcolor="#3d464d" data-color="#ffffff"><i class="fa fa-dropbox"></i></a></li>
						<li><a href="#" class="btn" data-bgcolor="#db4437" data-color="#ffffff"><i class="fa fa-google-plus"></i></a></li>
						<li><a href="#" class="btn" data-bgcolor="#00aff0" data-color="#ffffff"><i class="fa fa-skype"></i></a></li>
					</ul>
				</div>
				<div class="profile-skills">
					<h5 class="mb-20 h5 text-blue">Key Skills</h5>
					<h6 class="mb-5 font-14">HTML</h6>
					<div class="progress mb-20" style={{height: '6px'}}>
						<div class="progress-bar" role="progressbar" style={{width: '90%'}} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
					</div>
					<h6 class="mb-5 font-14">Css</h6>
					<div class="progress mb-20" style={{height: '6px'}}>
						<div class="progress-bar" role="progressbar" style={{width: '70%'}} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
					</div>
					<h6 class="mb-5 font-14">jQuery</h6>
					<div class="progress mb-20" style={{height: '6px'}}>
						<div class="progress-bar" role="progressbar" style={{width: '60%'}} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
					</div>
					<h6 class="mb-5 font-14">Bootstrap</h6>
					<div class="progress mb-20" style={{height: '6px'}}>
						<div class="progress-bar" role="progressbar" style={{width: '80%'}} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
					</div>
	
				</div>
	
			</div>

		</div>
	
          
					<div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 mb-30">
						<div class="card-box height-100-p overflow-hidden">
							<div class="profile-tab height-100-p">
								<div class="tab height-100-p">
									<ul class="nav nav-tabs customtab" role="tablist">
										<li class="nav-item">
											<a class="nav-link active" data-toggle="tab" href="#timeline" role="tab">Timeline</a>
										</li>
										<li class="nav-item">
											<a class="nav-link" data-toggle="tab" href="#tasks" role="tab">Tasks</a>
										</li>
										<li class="nav-item">
											<a class="nav-link" data-toggle="tab" href="#setting" role="tab">Settings</a>
										</li>
									</ul>
									<div class="tab-content">
										{/* <!-- Timeline Tab start --> */}
										<div class="tab-pane fade show active" id="timeline" role="tabpanel">
											<div class="pd-20">
												<div class="profile-timeline">
													<div class="timeline-month">
														<h5>August, 2020</h5>
													</div>
													<div class="profile-timeline-list">
														<ul>
															<li>
																<div class="date">12 Aug</div>
																<div class="task-name">Task Added</div>
																<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
																<div class="task-time">09:30 am</div>
															</li>
															<li>
																<div class="date">10 Aug</div>
																<div class="task-name">Task Added</div>
																<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
																<div class="task-time">09:30 am</div>
															</li>
															<li>
																<div class="date">10 Aug</div>
																<div class="task-name"> Event Added</div>
																<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
																<div class="task-time">09:30 am</div>
															</li>
															<li>
																<div class="date">10 Aug</div>
																<div class="task-name">Event Added</div>
																<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
																<div class="task-time">09:30 am</div>
															</li>
														</ul>
													</div>
													<div class="timeline-month">
														<h5>July, 2020</h5>
													</div>
													<div class="profile-timeline-list">
														<ul>
															<li>
																<div class="date">12 July</div>
																<div class="task-name"> Task Added</div>
																<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
																<div class="task-time">09:30 am</div>
															</li>
															<li>
																<div class="date">10 July</div>
																<div class="task-name">Task Added</div>
																<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
																<div class="task-time">09:30 am</div>
															</li>
														</ul>
													</div>
													<div class="timeline-month">
														<h5>June, 2020</h5>
													</div>
													<div class="profile-timeline-list">
														<ul>
															<li>
																<div class="date">12 June</div>
																<div class="task-name"> Task Added</div>
																<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
																<div class="task-time">09:30 am</div>
															</li>
															<li>
																<div class="date">10 June</div>
																<div class="task-name"> Task Added</div>
																<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
																<div class="task-time">09:30 am</div>
															</li>
															<li>
																<div class="date">10 June</div>
																<div class="task-name">Event Added</div>
																<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
																<div class="task-time">09:30 am</div>
															</li>
														</ul>
													</div>
												</div>
											</div>
                      
										</div>
                    
										{/* <!-- Timeline Tab End --> */}
										{/* <!-- Tasks Tab start --> */}
										<div class="tab-pane fade" id="tasks" role="tabpanel">
											<div class="pd-20 profile-task-wrap">
												<div class="container pd-0">
													{/* <!-- Open Task start --> */}
													<div class="task-title row align-items-center">
														<div class="col-md-8 col-sm-12">
															<h5>Open Tasks (4 Left)</h5>
														</div>
														<div class="col-md-4 col-sm-12 text-right">
															<a href="task-add" data-toggle="modal" data-target="#task-add" class="bg-light-blue btn text-blue weight-500"><i class="ion-plus-round"></i> Add</a>
														</div>
													</div>
													<div class="profile-task-list pb-30">
														<ul>
															<li>
																<div class="custom-control custom-checkbox mb-5">
																	<input type="checkbox" class="custom-control-input" id="task-1"/>
																	<label class="custom-control-label" htmlFor="task-1"></label>
																</div>
																<div class="task-type">Email</div>
																Lorem ipsum dolor sit amet, consectetur adipisicing elit. Id ea earum.
																<div class="task-assign">Assigned to Ferdinand M. <div class="due-date">due date <span>22 February 2019</span></div></div>
															</li>
															<li>
																<div class="custom-control custom-checkbox mb-5">
																	<input type="checkbox" class="custom-control-input" id="task-2"/>
																	<label class="custom-control-label" htmlFor="task-2"></label>
																</div>
																<div class="task-type">Email</div>
																Lorem ipsum dolor sit amet.
																<div class="task-assign">Assigned to Ferdinand M. <div class="due-date">due date <span>22 February 2019</span></div></div>
															</li>
															<li>
																<div class="custom-control custom-checkbox mb-5">
																	<input type="checkbox" class="custom-control-input" id="task-3"/>
																	<label class="custom-control-label" htmlFor="task-3"></label>
																</div>
																<div class="task-type">Email</div>
																Lorem ipsum dolor sit amet, consectetur adipisicing elit.
																<div class="task-assign">Assigned to Ferdinand M. <div class="due-date">due date <span>22 February 2019</span></div></div>
															</li>
															<li>
																<div class="custom-control custom-checkbox mb-5">
																	<input type="checkbox" class="custom-control-input" id="task-4"/>
																	<label class="custom-control-label" htmlFor="task-4"></label>
																</div>
																<div class="task-type">Email</div>
																Lorem ipsum dolor sit amet. Id ea earum.
																<div class="task-assign">Assigned to Ferdinand M. <div class="due-date">due date <span>22 February 2019</span></div></div>
															</li>
														</ul>
													</div>
													{/* <!-- Open Task End --> */}
													{/* <!-- Close Task start --> */}
													<div class="task-title row align-items-center">
														<div class="col-md-12 col-sm-12">
															<h5>Closed Tasks</h5>
														</div>
													</div>
													<div class="profile-task-list close-tasks">
														<ul>
															<li>
																<div class="custom-control custom-checkbox mb-5">
																	<input type="checkbox" class="custom-control-input" id="task-close-1" checked="" disabled=""/>
																	<label class="custom-control-label" htmlFor="task-close-1"></label>
																</div>
																<div class="task-type">Email</div>
																Lorem ipsum dolor sit amet, consectetur adipisicing elit. Id ea earum.
																<div class="task-assign">Assigned to Ferdinand M. <div class="due-date">due date <span>22 February 2018</span></div></div>
															</li>
															<li>
																<div class="custom-control custom-checkbox mb-5">
																	<input type="checkbox" class="custom-control-input" id="task-close-2" checked="" disabled=""/>
																	<label class="custom-control-label" htmlFor="task-close-2"></label>
																</div>
																<div class="task-type">Email</div>
																Lorem ipsum dolor sit amet.
																<div class="task-assign">Assigned to Ferdinand M. <div class="due-date">due date <span>22 February 2018</span></div></div>
															</li>
															<li>
																<div class="custom-control custom-checkbox mb-5">
																	<input type="checkbox" class="custom-control-input" id="task-close-3" checked="" disabled=""/>
																	<label class="custom-control-label" htmlFor="task-close-3"></label>
																</div>
																<div class="task-type">Email</div>
																Lorem ipsum dolor sit amet, consectetur adipisicing elit.
																<div class="task-assign">Assigned to Ferdinand M. <div class="due-date">due date <span>22 February 2018</span></div></div>
															</li>
															<li>
																<div class="custom-control custom-checkbox mb-5">
																	<input type="checkbox" class="custom-control-input" id="task-close-4" checked="" disabled=""/>
																	<label class="custom-control-label" htmlFor="task-close-4"></label>
																</div>
																<div class="task-type">Email</div>
																Lorem ipsum dolor sit amet. Id ea earum.
																<div class="task-assign">Assigned to Ferdinand M. <div class="due-date">due date <span>22 February 2018</span></div></div>
															</li>
														</ul>
													</div>
													{/* <!-- Close Task start --> */}
													{/* <!-- add task popup start --> */}
													<div class="modal fade customscroll" id="task-add" tabIndex="-1" role="dialog">
														<div class="modal-dialog modal-dialog-centered" role="document">
															<div class="modal-content">
																<div class="modal-header">
																	<h5 class="modal-title" id="exampleModalLongTitle">Tasks Add</h5>
																	<button type="button" class="close" data-dismiss="modal" aria-label="Close" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Close Modal">
																		<span aria-hidden="true">&times;</span>
																	</button>
																</div>
																<div class="modal-body pd-0">
																	<div class="task-list-form">
																		<ul>
																			<li>
																				<form>
																					<div class="form-group row">
																						<label class="col-md-4">Task Type</label>
																						<div class="col-md-8">
																							<input type="text" class="form-control"/>
																						</div>
																					</div>
																					<div class="form-group row">
																						<label class="col-md-4">Task Message</label>
																						<div class="col-md-8">
																							<textarea class="form-control"></textarea>
																						</div>
																					</div>
																					<div class="form-group row">
																						<label class="col-md-4">Assigned to</label>
																						<div class="col-md-8">
																							<select class="selectpicker form-control" data-style="btn-outline-primary" title="Not Chosen" multiple="" data-selected-text-format="count" data-count-selected-text= "{0} people selected">
																								<option>Ferdinand M.</option>
																								<option>Don H. Rabon</option>
																								<option>Ann P. Harris</option>
																								<option>Katie D. Verdin</option>
																								<option>Christopher S. Fulghum</option>
																								<option>Matthew C. Porter</option>
																							</select>
																						</div>
																					</div>
																					<div class="form-group row mb-0">
																						<label class="col-md-4">Due Date</label>
																						<div class="col-md-8">
																							<input type="text" class="form-control date-picker"/>
																						</div>
																					</div>
																				</form>
																			</li>
																			<li>
																				<a href="javascript:;" class="remove-task"  data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Remove Task"><i class="ion-minus-circled"></i></a>
																				<form>
																					<div class="form-group row">
																						<label class="col-md-4">Task Type</label>
																						<div class="col-md-8">
																							<input type="text" class="form-control"/>
																						</div>
																					</div>
																					<div class="form-group row">
																						<label class="col-md-4">Task Message</label>
																						<div class="col-md-8">
																							<textarea class="form-control"></textarea>
																						</div>
																					</div>
																					<div class="form-group row">
																						<label class="col-md-4">Assigned to</label>
																						<div class="col-md-8">
																							<select class="selectpicker form-control" data-style="btn-outline-primary" title="Not Chosen" multiple="" data-selected-text-format="count" data-count-selected-text= "{0} people selected">
																								<option>Ferdinand M.</option>
																								<option>Don H. Rabon</option>
																								<option>Ann P. Harris</option>
																								<option>Katie D. Verdin</option>
																								<option>Christopher S. Fulghum</option>
																								<option>Matthew C. Porter</option>
																							</select>
																						</div>
																					</div>
																					<div class="form-group row mb-0">
																						<label class="col-md-4">Due Date</label>
																						<div class="col-md-8">
																							<input type="text" class="form-control date-picker"/>
																						</div>
																					</div>
																				</form>
																			</li>
																		</ul>
																	</div>
																	<div class="add-more-task">
																		<a href="#" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Add Task"><i class="ion-plus-circled"></i> Add More Task</a>
																	</div>
																</div>
																<div class="modal-footer">
																	<button type="button" class="btn btn-primary">Add</button>
																	<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
																</div>
															</div>
														</div>
													</div>
													{/* <!-- add task popup End --> */}
												</div>
											</div>
										</div>
										{/* <!-- Tasks Tab End --> */}
										{/* <!-- Setting Tab start --> */}
										<div class="tab-pane fade height-100-p" id="setting" role="tabpanel">
											<div class="profile-setting">
												<form>
													<ul class="profile-edit-list row">
														<li class="weight-500 col-md-6">
															<h4 class="text-blue h5 mb-20">Edit Your Personal Setting</h4>
															<div class="form-group">
																<label>Full Name</label>
																<input onChange={(e)=>setuserName(e.target.value)} class="form-control form-control-lg" type="text"/>
															</div>
															<div class="form-group">
																<label>Title</label>
																<input onChange={(e)=>setjobTitle(e.target.value)} class="form-control form-control-lg" type="text"/>
															</div>
															<div class="form-group">
																<label>Email</label>
																<input onChange={(e)=>setemail(e.target.value)} class="form-control form-control-lg" type="email"/>
															</div>
															<div class="form-group">
																<label>Date of birth</label>
																<input onChange={(e)=>setDOB(e.target.value)} class="form-control form-control-lg date-picker" type="date"/>
															</div>
															<div onChange={(e)=>setgender(e.target.value)} class="form-group">
																<label>Gender</label>
																<div class="d-flex">
																<div class="custom-control custom-radio mb-5 mr-20">
																	<input value='male' type="radio" id="customRadio4" name="customRadio" class="custom-control-input"/>
																	<label class="custom-control-label weight-400" htmlFor="customRadio4">Male</label>
																</div>
																<div class="custom-control custom-radio mb-5">
																	<input value='female' type="radio" id="customRadio5" name="customRadio" class="custom-control-input"/>
																	<label class="custom-control-label weight-400" htmlFor="customRadio5">Female</label>
																</div>
																</div>
															</div>
															<div class="form-group">
																<label>Country</label>
																<input onChange={(e)=>setcountry(e.target.value)} class="form-control form-control-lg" type="text"/>
															</div>
															<div class="form-group">
																<label>State/Province/Region</label>
																<input onChange={(e)=>setstate(e.target.value)} class="form-control form-control-lg" type="text"/>
															</div>
															<div class="form-group">
																<label>Postal Code</label>
																<input onChange={(e)=>setpincode(e.target.value)} class="form-control form-control-lg" type="tel"/>
															</div>
															<div class="form-group">
																<label>Phone Number</label>
																<input onChange={(e)=>setcontact(e.target.value)} class="form-control form-control-lg" type="tel"/>
															</div>
															<div class="form-group">
																<label>Address</label>
																<textarea onChange={(e)=>setcity(e.target.value)} class="form-control"></textarea>
															</div>
															<div class="form-group">
																<label>Profile Image</label>
																<input onChange={(e)=>setprofileImage1(e.target.files)} class="form-control form-control-lg" type="file"/>
															</div>
															<div class="form-group mb-0">
																<input 
																onClick={(e)=>UploadImage(e)} 
																type="button" class="btn btn-primary" value="Upload Image"/>
															</div>
															{/* <div class="form-group">
																<label>Paypal ID</label>
																<input class="form-control form-control-lg" type="text"/>
															</div>
															<div class="form-group">
																<div class="custom-control custom-checkbox mb-5">
																	<input type="checkbox" class="custom-control-input" id="customCheck1-1"/>
																	<label class="custom-control-label weight-400" htmlFor="customCheck1-1">I agree to receive notification emails</label>
																</div>
															</div> */}
															{localStorage.getItem('role') == 'employee' ?
															<div class="form-group mb-0">
																<input 
																onClick={(e)=>EmpProfileUpdate(e)} 
																type="button" class="btn btn-primary" value="Update Information"/>
															</div>:""}
														</li>
														{localStorage.getItem('role') == 'employee' ?
															"":
														<li class="weight-500 col-md-6">
															<h4 class="text-blue h5 mb-20">Restricted for Employees</h4>
															<div class="form-group">
															<Box sx={{ minWidth: 120,marginTop:'25px' }}>
																<FormControl fullWidth>
																<InputLabel variant="standard" htmlFor="uncontrolled-native">
																Permission 
																</InputLabel>
																<NativeSelect
																	defaultValue={"read"}
																	inputProps={{
																		name: 'permission',
																		id: 'uncontrolled-native',
																	}}
																	onChange={Permission}
																>
																	<option value={'read'}>Read</option>
																	<option value={'edit'}>Edit</option>
																	<option value={'delete'}>Delete</option>
																</NativeSelect>
																</FormControl>
															</Box>
															</div>
															<div class="form-group">
																<label>Password Reset:</label>
																<input onChange={(e)=>setpassword(e.target.value)} class="form-control form-control-lg" type="password" placeholder="Reset Password"/>
															</div>
														
															<div class="form-group mb-0">
																<input 
																onClick={(e)=>EmpProfileUpdate(e)}
																type="button" class="btn btn-primary" value="Save & Update"/>

                                                                <input                                                       
																onClick={(e)=>EmpProfileDelete(e)}
																type="button" class="btn btn-danger ml-4" value="Delete Profile"/>
															</div>
														</li>}
													</ul>
												</form>
											</div>
										</div>
										{/* <!-- Setting Tab End --> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</div>
	</div>
	<Helmet>
	<script src="vendors/scripts/core.js"></script>
	<script src="vendors/scripts/script.min.js"></script>
	<script src="vendors/scripts/process.js"></script>
	<script src="vendors/scripts/layout-settings.js"></script>
	<script src="src/plugins/cropperjs/dist/cropper.js"></script>
	</Helmet>
    </div>
  )
}
