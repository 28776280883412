import React from 'react'
import { Helmet } from 'react-helmet'
import Header from '../COMPONENTS/header'
import LoadingPage from '../COMPONENTS/loadingPage'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function AddSubscription() {
const token = localStorage.getItem('token')
const [subscriptionPlanName, setsubscriptionPlanName] = useState('')
const [subscriptionAmount, setsubscriptionAmount] = useState('')
const [subscriptionPlanDays,setsubscriptionPlanDays] = useState('')
const [description, setdescription] = useState('')
const nav = useNavigate()
const subscriptionData = {subscriptionPlanName,subscriptionAmount,description,subscriptionPlanDays}
    function addSubscription(e){
		e.preventDefault()
		fetch('https://node.firehrm.com/FK/admin/create-package',{
			method:'POST',
			headers:{
				'Authorization': token,
				'Content-Type': 'application/json'
			  },
			body: JSON.stringify(subscriptionData)
			})
			.then((response)=> response.json())
			
			.then((data) => {
			
				console.log('Response:', data);
				nav('/subscription')
			})	
			.catch((error) => {
			console.log('Error:', error);
			});
		}

  return (
    <div>
        <div>
           {/* <LoadingPage/> */}
           <Header/>
<div class="main-container">
<div class="pd-ltr-5 xs-pd-5-5">
	<div class="min-height-200px"></div>
	<div class="pd-20 card-box mb-30">
	<div class="clearfix">
		<div class="pull-left">
			<h4 class="text-blue h4">Add Subscription Plan</h4>
			<p class="mb-30">Add new subscription plan with rate and description</p>
		</div>
	</div>
	<form>
		<div class="form-group row">
			<label class="col-sm-12 col-md-2 col-form-label">Subscription Plan</label>
			<div class="col-sm-12 col-md-10">
				<input onChange={(e)=>setsubscriptionPlanName(e.target.value)} class="form-control" type="text"/>
			</div>
		</div>
		
		<div class="form-group row">
			<label class="mr-5 ml-3">Rate</label>
			<div style={{marginLeft:'100px'}}>
				<input onChange={(e)=>setsubscriptionAmount(e.target.value)} class="form-control" type="text"/>
			</div>
        </div>

		<div class="form-group row">
			<label class="mr-5 ml-3">Validity Days</label>
			<div style={{marginLeft:'50px'}}>
				<input onChange={(e)=>setsubscriptionPlanDays(e.target.value)} class="form-control" type="text"/>
			</div>
        </div>


        <div class="form-group row">
			<label class="mr-5 ml-3">Description</label>
			<div style={{marginLeft:'55px'}}>
				<textarea onChange={(e)=>setdescription(e.target.value)} class="form-control" rows="4" cols="75" />
			</div>
        </div>


		<div className="row">
			<div className="col-sm-12">
				<div className="input-group mb-0">
					<button onClick={(e)=>addSubscription(e)} className="btn btn-outline-primary btn-lg btn-block" >Create Subscription Plan</button>
				</div>
			</div>
		
        </div>
	</form>
</div>

           <Helmet>
           <script src="vendors/scripts/core.js"></script>
            <script src="vendors/scripts/script.min.js"></script>
            <script src="vendors/scripts/process.js"></script>
            <script src="vendors/scripts/layout-settings.js"></script>
           </Helmet>
        </div>
	</div>
				
</div>
    </div>
  )
}
