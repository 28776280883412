import React,{useEffect,useState} from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

export default function EmployeesListComponent() {
	const [employeeListData, setemployeeListData] = useState('')
    const token = localStorage.getItem('token')
    const nav = useNavigate()

	async function getCall() {
		await axios({
		  'method': 'GET',
		  'url': `https://node.firehrm.com/FK/admin/getAll-subAdmin`
		  ,
		  'headers': {
			'Content-Type': 'application/json',
		    'Authorization': token
		  },
		}).then((data) => {
		  console.log(data)
		  setemployeeListData(data.data.data)
		}).catch((error)=>{
			console.log(error);
		})
	  };

	  useEffect(()=>{getCall()},[])

	  function viewFullProfile(e,x){
          nav('/viewEmployeeProfile',{state:x})
	  }
  return (
    <div>
		
        <div class="main-container">
		<div class="pd-ltr-20 xs-pd-20-10">
			<div class="min-height-200px">
				<div class="page-header">
					<div class="row">
						<div class="col-md-12 col-sm-12">
							<div class="title">
								<h4>Sub Admin</h4>
							</div>
							<nav aria-label="breadcrumb" role="navigation">
								<ol class="breadcrumb">
									<li class="breadcrumb-item"><a href="home">Home</a></li>
									<li class="breadcrumb-item active" aria-current="page">Sub-Admin List</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
				
				<div class="product-wrap">
				
					<div class="product-list">
						<ul class="row">
			{employeeListData.length == 0 ? <h5> No Data Found </h5>:
			employeeListData.length >= 0 && employeeListData.map((x) =>
				<>
							<li class="col-lg-4 col-md-6 col-sm-12">
								<div class="product-box">
									<div class="producct-img"><img src={x.profileImage} alt=""/></div>
									<div class="product-caption">
										<h4><a href="#">{x.userName}</a></h4>
										<div class="price">
											<ins>{x.permission}</ins>
										</div>
										<button onClick={(e)=>viewFullProfile(e,x)} class="btn btn-outline-primary">Read More</button>
									</div>
								</div>
							</li>
				</>
				)}
						</ul>
					</div>
					
					<div class="blog-pagination mb-30">
						<div class="btn-toolbar justify-content-center mb-15">
							<div class="btn-group">
								<a href="#" class="btn btn-outline-primary prev"><i class="fa fa-angle-double-left"></i></a>
								<a href="#" class="btn btn-outline-primary">1</a>
								<a href="#" class="btn btn-outline-primary">2</a>
								<span class="btn btn-primary current">3</span>
								<a href="#" class="btn btn-outline-primary">4</a>
								<a href="#" class="btn btn-outline-primary">5</a>
								<a href="#" class="btn btn-outline-primary next"><i class="fa fa-angle-double-right"></i></a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	
    </div>
  )
}
