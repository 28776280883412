import React from 'react'
import { Helmet } from 'react-helmet'
import Header from '../COMPONENTS/header'
import LoadingPage from '../COMPONENTS/loadingPage'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export default function UpdateFAQ() {
const state = useLocation()
const token = localStorage.getItem('token')
const [question, setQuestion] = useState(state.state.question)
const [answer, setAnswer] = useState(state.state.answer)
const faq = {question,answer}
const nav =useNavigate()
console.log(state);

    function addfaq(e){
		e.preventDefault()
		fetch('https://node.firehrm.com/FK/admin/update-FAQ/'+state.state._id,{
			method:'PUT',
			headers:{
				'Authorization': token,
				'Content-Type': 'application/json'
			  },
			body: JSON.stringify(faq)
			})
			.then((response)=> response.json())
			
			.then((data) => {
			
				console.log('Response:', data);
				nav('/viewFAQ')
			})	
			.catch((error) => {
			console.log('Error:', error);
			});
		}

  return (
    <div>
        <div>
        <div>
           {/* <LoadingPage/> */}
           <Header/>
<div class="main-container">
<div class="pd-ltr-5 xs-pd-5-5">
	<div class="min-height-200px"></div>
	<div class="pd-20 card-box mb-30">
	<div class="clearfix">
		<div class="pull-left">
			<h4 class="text-blue h4">Edit FAQ's</h4>
			<p class="mb-30">Edit question and answer for FAQ</p>
		</div>
	</div>
	<form>
		<div class="form-group row">
			<label class="col-sm-12 col-md-2 col-form-label">Question</label>
			<div class="col-sm-12 col-md-10">
				<input placeholder={state.state.question} onChange={(e)=>setQuestion(e.target.value)} class="form-control" type="text"/>
			</div>
		</div>
		
		<div class="form-group row">
			<label class="mr-5 ml-3">Answer</label>
			<div style={{marginLeft:'85px'}}>
				<textarea rows="4" cols="76" placeholder={state.state.answer}
                 onChange={(e)=>setAnswer(e.target.value)} class="form-control" type="text"/>
			</div>
        </div>

		<div className="row">
			<div className="col-sm-12">
				<div className="input-group mb-0">
					<button onClick={(e)=>addfaq(e)} className="btn btn-outline-primary btn-lg btn-block" >Edit FAQ</button>
				</div>
			</div>
		
        </div>
	</form>
</div>

           <Helmet>
           <script src="vendors/scripts/core.js"></script>
            <script src="vendors/scripts/script.min.js"></script>
            <script src="vendors/scripts/process.js"></script>
            <script src="vendors/scripts/layout-settings.js"></script>
           </Helmet>
        </div>
	</div>
				
</div>
    </div>
    </div>
  )
}
