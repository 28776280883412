import { Routes, Route } from "react-router-dom";
import "./App.css";
import Login from "./PAGES/login";
import Register from "./PAGES/register";
import Home from "./PAGES/home";
import EmployeesList from "./PAGES/employeesList";
import ViewEmployeeProfile from "./PAGES/viewEmployeeProfile";
import Categories from "./PAGES/addCategories";
import CategoriesList from "./PAGES/categoriesList";
import CategoryView from "./PAGES/categoryView";
import EditCategory from "./PAGES/editCategory";
import Subscription from './PAGES/subscription'
import AddSubscription from "./PAGES/addSubscription";
import UpdatePlan from "./PAGES/updatePlan";
import AddFAQ from "./PAGES/addFAQ";
import ViewFAQ from "./PAGES/viewFAQ";
import UpdateFAQ from "./PAGES/updateFAQ";
import ViewProductList from "./PAGES/viewProductList";
import ViewUserList from "./PAGES/viewUserList";
import GetAllPaymentDetails from "./PAGES/getAllPaymentDetails";
import AllSubscribeDetails from "./PAGES/allSubscribeDetails";

function App() {
    return ( <div >
        <Routes >
        <Route exact path = "/" element = { < Login / > }/> 
        <Route path = "/register"element = { < Register / > }/> 
        <Route path = "/home"element = { < Home / > }/> 
        <Route path = "/employeesList"element = { < EmployeesList / > }/> 
        <Route path = "/viewEmployeeProfile"element = { < ViewEmployeeProfile / > }/>
        <Route path = "/categoriesList"element = { < CategoriesList / > }/> 
        <Route path = "/categoryView"element = { < CategoryView / > }/> 
        <Route path = "/editCategory"element = { < EditCategory / > }/> 
        < Route path = "/subscription"element = { < Subscription / > }/>
        < Route path = "/addSubscription"element = { < AddSubscription / > }/> 
        < Route path = "/updatePlan"element = { < UpdatePlan / > }/> 
        < Route path = "/addFAQ"element = { < AddFAQ / > }/> 
        < Route path = "/viewFAQ"element = { < ViewFAQ / > }/>
        < Route path = "/updateFAQ"element = { < UpdateFAQ / > }/> 
        < Route path = "/addCategories"element = { < Categories / > }/> 
        < Route path = "/viewproductlist"element = { < ViewProductList / > }/> 
        < Route path = "/viewUserlist"element = { < ViewUserList / > }/>
        < Route path = "/getAllPaymentDetails" element = { < GetAllPaymentDetails / > }/>
        < Route path = "/allSubscribeDetails" element = { < AllSubscribeDetails / > }/>
        </Routes> 
        </div>
    );
}

export default App;