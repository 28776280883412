import React from 'react'
import { Helmet } from 'react-helmet';
import EmployeesListComponent from '../COMPONENTS/employeesListComponent'
import Header from '../COMPONENTS/header'
import LoadingPage from '../COMPONENTS/loadingPage'

export default function EmployeesList() {
  return (
    <div>
      {/* <LoadingPage/> */}
      <Header/>
      <EmployeesListComponent/>

      <Helmet>
      <script src="vendors/scripts/core.js"></script>
      <script src="vendors/scripts/script.min.js"></script>
      <script src="vendors/scripts/process.js"></script>
      <script src="vendors/scripts/layout-settings.js"></script>
      </Helmet>
    </div>
  );
}
