import React from 'react'
import LoadingPage from '../COMPONENTS/loadingPage'
import Helmet from 'react-helmet'
import Header from '../COMPONENTS/header'
import HomeTables from '../COMPONENTS/homeTables'

export default function Home() {
  return (
    <div>
       {/* <LoadingPage/> */}
       <Header/>
       <HomeTables/>

       <Helmet>
        <script src="vendors/scripts/core.js"></script>
        <script src="vendors/scripts/script.min.js"></script>
        <script src="vendors/scripts/process.js"></script>
        <script src="vendors/scripts/layout-settings.js"></script>
        <script src="src/plugins/apexcharts/apexcharts.min.js"></script>
        <script src="src/plugins/datatables/js/jquery.dataTables.min.js"></script>
        <script src="src/plugins/datatables/js/dataTables.bootstrap4.min.js"></script>
        <script src="src/plugins/datatables/js/dataTables.responsive.min.js"></script>
        <script src="src/plugins/datatables/js/responsive.bootstrap4.min.js"></script>
        <script src="vendors/scripts/dashboard.js"></script>
       </Helmet>
    </div>
  )
}
