import React,{useState,useEffect} from 'react'
import { Helmet } from 'react-helmet'
import Header from '../COMPONENTS/header'
import LoadingPage from '../COMPONENTS/loadingPage'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

function CategoriesList() {
const token = localStorage.getItem('token')
const nav = useNavigate()
const [categoriesData,setCategoriesData] = useState('')
	async function getCategories() {
		await axios({
		  'method': 'GET',
		  'url': `https://node.firehrm.com/FK/admin/getAll-category`,
		  'headers': {
			//   'Authorization':token,
			'Content-Type': 'application/json',
		  },
		}).then((data) => {
		  console.log(data)
		setCategoriesData(data?.data?.data)
		}).catch((error)=>{
			console.log(error);
		})
	  };

	  useEffect(()=>{getCategories()},[])

	  function viewCategory(e,x){
		nav('/categoryView',{state:x})
	}

    return (
       <div>
           {/* <LoadingPage/> */}
           <Header/>

    <div class="main-container">
		<div class="pd-ltr-20 xs-pd-20-10">
			<div class="min-height-200px">
				<div class="page-header">
					<div class="row">
						<div class="col-md-6 col-sm-12">
							<div class="title">
								<h4>Categories</h4>
							</div>
							<nav aria-label="breadcrumb" role="navigation">
								<ol class="breadcrumb">
									<li class="breadcrumb-item"><a href="home">Home</a></li>
									<li class="breadcrumb-item active" aria-current="page">Categories List</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
				{/* <!-- Simple Datatable start --> */}
				<div class="card-box mb-30">
					<div class="pd-20">
						<h4 class="text-blue h4">Categories List</h4>
						<p class="mb-0">List of all categories in the Firekeey</p>
					</div>
					<div class="pb-20">
						<table class="data-table table stripe hover nowrap">
							<thead>
								<tr>
									<th class="table-plus datatable-nosort">Category</th>
									
									<th>Sub Categoires</th>
									
									<th class="datatable-nosort">Action</th>

									<th class="datatable-nosort"><a href='addcategories' className="btn btn-success btn-lg" >Add New</a></th>
								</tr>
							</thead>
							<tbody>
								{categoriesData && categoriesData.map((singleCategory)=>
								
								<tr>
									<td class="table-plus">{singleCategory.categoryName}</td>
									
									<td>{singleCategory.subCategoryName.length}</td>
									
									<td>
									   <button onClick={(e)=>viewCategory(e,singleCategory)} className="btn btn-info btn-lg" >View</button>
									</td>
								</tr>
								)}
							</tbody>
						</table>
					</div>
				</div>

           <Helmet>
                {/* <!-- js --> */}
	<script src="vendors/scripts/core.js"></script>
	<script src="vendors/scripts/script.min.js"></script>
	<script src="vendors/scripts/process.js"></script>
	<script src="vendors/scripts/layout-settings.js"></script>
	<script src="src/plugins/datatables/js/jquery.dataTables.min.js"></script>
	<script src="src/plugins/datatables/js/dataTables.bootstrap4.min.js"></script>
	<script src="src/plugins/datatables/js/dataTables.responsive.min.js"></script>
	<script src="src/plugins/datatables/js/responsive.bootstrap4.min.js"></script>
	{/* <!-- buttons for Export datatable --> */}
	<script src="src/plugins/datatables/js/dataTables.buttons.min.js"></script>
	<script src="src/plugins/datatables/js/buttons.bootstrap4.min.js"></script>
	<script src="src/plugins/datatables/js/buttons.print.min.js"></script>
	<script src="src/plugins/datatables/js/buttons.html5.min.js"></script>
	<script src="src/plugins/datatables/js/buttons.flash.min.js"></script>
	<script src="src/plugins/datatables/js/pdfmake.min.js"></script>
	<script src="src/plugins/datatables/js/vfs_fonts.js"></script>
	{/* <!-- Datatable Setting js --> */}
	<script src="vendors/scripts/datatable-setting.js"></script>
           </Helmet>
           </div>
           </div>
        
        </div> 
       </div> 
    )
}

export default CategoriesList
