import React from 'react'
import { Helmet } from 'react-helmet'
import Header from '../COMPONENTS/header'
import LoadingPage from '../COMPONENTS/loadingPage'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

function Categories() {
const token = localStorage.getItem('token')
const [SubCategoryName, setaddSubCategoryName] = useState([{subCategory:''}])
const [categoryImage, setProfileImage] = useState('');
const [profileImage1,setProfileImage1]=useState('');
const subCategoryName = SubCategoryName
const [categoryName, setcategoryName] = useState('');
const nav = useNavigate()

console.log(SubCategoryName);

const handleAddSubcategory = (e) =>{
	e.preventDefault()
	setaddSubCategoryName([...SubCategoryName,{subCategory:''}])
}

const handleRemoveSubcategory = (e,index) =>{
	e.preventDefault()
	const list = [...SubCategoryName];
	list.splice(index,1);
	setaddSubCategoryName(list)
}

const handleServiceChange = (e,index) => {
	const{value} = e.target
	// console.log(name);
	console.log(value);
	const list = [...SubCategoryName]
	list[index] = value
	setaddSubCategoryName(list)
}

async function UploadImage(e) {
	e.preventDefault()
  const image =new FormData()
  image.append('image',profileImage1[0])
  console.log(profileImage1)
  await fetch('https://node.firehrm.com/FK/user/image',{ 
  method: 'POST',
  body:image
}).then((response)=>{
  console.log(response)
  return response.json()
}).then((resp)=>{console.log(resp);setProfileImage(resp.data.image); console.log(categoryImage);} )
.catch((error)=>{
  console.log(error)
})
}

	function CreateCategory(e){
		e.preventDefault()
		fetch('https://node.firehrm.com/FK/admin/addCategory',{
			method:'POST',
			headers:{
				'Authorization': token,
				'Content-Type': 'application/json'
			  },
			body: JSON.stringify({categoryName,subCategoryName,categoryImage})
			})
			.then((response)=> response.json())
			
			.then((data) => {
			
				console.log('Response:', data);
				nav('/categoriesList')
			})	
			.catch((error) => {
			console.log('Error:', error);
			});
		}

    return (
        <div>
           {/* <LoadingPage/> */}
           <Header/>
<div class="main-container">
<div class="pd-ltr-5 xs-pd-5-5">
	<div class="min-height-200px"></div>
	<div class="pd-20 card-box mb-30">
	<div class="clearfix">
		<div class="pull-left">
			<h4 class="text-blue h4">Add Categories</h4>
			<p class="mb-30">Add Categories with Sub-Categories</p>
		</div>
	</div>
	<form>
		<div class="form-group row">
			<label class="col-sm-12 col-md-2 col-form-label">Category</label>
			<div class="col-sm-12 col-md-10">
				<input onChange={(e)=>setcategoryName(e.target.value)} class="form-control" type="text" placeholder="Johnny Brown"/>
			</div>
		</div>
		<div class="form-group rowl">
		<div class="col-lg-9 " style={{marginLeft:'160px'}}>
			<div class="form-group">
				<input type="file" class="form-control" onChange={(e)=>
				setProfileImage1(e.target.files)}/>
			</div>
			<div class="col-lg-3">
			<button class="btn btn-inline btn-primary" onClick={(e)=>UploadImage(e)}>upload image</button>
		   </div>
		   
		</div>
		
		</div>
		{SubCategoryName.map((singleSubCategory,index)=>(
		<div key={index} class="form-group row">
			<label class="mr-5 ml-3">Sub-Category</label>
			<div class="ml-5">
				<input onChange={(e)=>handleServiceChange(e,index)} class="form-control" placeholder="Search Here" type="search"/>
			</div>
			<div className="" style = {{marginLeft:'10px'}}>
				<div className="">
					<button onClick={(e)=>handleRemoveSubcategory(e,index)} className="btn btn-outline-danger" >-</button>
				</div>
			</div>
			{SubCategoryName.length -1 === index &&
			<div className="row">
			<div className="row">
			<div className="col-md-3" style={{marginLeft:'400px',marginTop:'25px'}}>
				<div className="">
					<button onClick={(e)=>handleAddSubcategory(e)} className="btn btn-outline-primary btn-lg " >+</button>
				</div>
			</div>
		</div>
		
		</div>}
		
		</div>
		)
		)}
		<div className="row">
			<div className="col-sm-12">
				<div className="input-group mb-0">
					<button onClick={(e)=>CreateCategory(e)} className="btn btn-outline-primary btn-lg btn-block" >Create Category</button>
				</div>
			</div>
		</div>
	</form>
</div>

    
            
           <Helmet>
           <script src="vendors/scripts/core.js"></script>
            <script src="vendors/scripts/script.min.js"></script>
            <script src="vendors/scripts/process.js"></script>
            <script src="vendors/scripts/layout-settings.js"></script>
           </Helmet>
        </div>
	</div>
				
</div>
    )
}

export default Categories
