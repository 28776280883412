import React,{useState,useEffect} from 'react'
import axios from 'axios';
import Header from '../COMPONENTS/header';

export default function GetAllPaymentDetails() {
    const token = localStorage.getItem('token')
    const [PaymentData, setPaymentData] = useState('');

    async function getPaymentDetails() {
		await axios({
		  'method': 'GET',
		  'url': `https://node.firehrm.com/FK/owner/getAll-payment`,
		  'headers': {
			  'Authorization':token,
			'Content-Type': 'application/json',
		  },
		}).then((data) => {
		  console.log(data)
		setPaymentData(data.data.data)

		}).catch((error)=>{
			console.log(error);
		})
	  };

      useEffect(()=>{getPaymentDetails()},[])

  return (
    <div>
    <Header/>
    <div class="main-container">
		<div class="pd-ltr-20">
        <div class="card-box mb-30">
				{PaymentData?<h2 class="h4 pd-20">All Payment Details</h2>:''}
				<table class="data-table table nowrap">
					<thead>
						{PaymentData?
                        <tr>
							{/* <th class="table-plus datatable-nosort">Image</th> */}
							<th>Date</th>
							<th>Payment status</th>
							<th>Amount</th>
							<th>Plan Validity</th>
							<th>Plan Name</th>
							{/* <th class="datatable-nosort">Action</th> */}
						</tr>
                        :""} 
					</thead>
					<tbody>
						{PaymentData && PaymentData?.map((x=>
                        <tr>
							{/* <td class="table-plus">
								<img src={x?.profileImage} width="70" height="70" alt=""/>
							</td> */}
							<td>
								<h5 class="font-16">{x?.Date}</h5>
							</td>
							<td>{x?.paymentOn}</td>
							<td>{x?.amount}</td>
							<td>{x?.subscribeInformation?.subscriptionPlanDays}</td>
							<td>{x?.subscribeInformation?.subscriptionPlanName}</td>
							{/* <td>
								<div class="dropdown">
									<a class="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle" href="#" role="button" data-toggle="dropdown">
										<i class="dw dw-more"></i>
									</a>
									<div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
										<a class="dropdown-item" href="#"><i class="dw dw-eye"></i> View</a>
										<a class="dropdown-item" href="#"><i class="dw dw-edit2"></i> Edit</a>
										<a class="dropdown-item" href="#"><i class="dw dw-delete-3"></i> Delete</a>
									</div>
								</div>
							</td> */}
						</tr>
                        ))}

					</tbody>
                    
				</table>
			</div>
        </div>
    </div>

    </div>
  )
}

